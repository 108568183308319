<template>
  <div v-if="step" class="step__body">
    <h3>{{ step.name }}</h3>
    <p v-if="step.description">{{ step.description }}</p>
    <div class="row">
      <div class="col-sm-5">
        <div class="input-group mb-2">
          <div class="input-group-prepend">
            <div class="input-group-text">Breite:</div>
          </div>
          <input type="text" class="form-control" @change="onChange" v-model="width">
        </div>
        <div class="input-group mb-2">
          <div class="input-group-prepend">
            <div class="input-group-text">Höhe:</div>
          </div>
          <input type="text" class="form-control" @change="onChange" v-model="height">
        </div>
        <div v-if="dimGarage && dimGarage.length > 0">
          <strong>Alternative Maße und Preise:</strong>
          <ul class="list-group">
            <li v-for="(p, index) in dimGarage" :key="index" class="list-group-item" @click="onSelectItem(p.width, p.height)">
              <strong>{{ p.width }}</strong>x<strong>{{ p.height }}</strong>:
              <span class="text-danger">{{ $filters.currency(p.price ) }}</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-sm-7">
        <strong>Einbaumaße(Innenansicht):</strong>
        <p class="step-description">
          <i class="fa fa-fw fa-exclamation-triangle  text-danger"></i>Bitte beachten Sie die Einbau- und Mindestmaßen. Diese sind wichtig für die richtige Montage und einwandreie Funktion des Garagentores.
        </p>
        <div class="row">
          <div class="col-sm-5 mt-5">
            <img src="@/assets/img/measure.jpg" width="260px">
          </div>
          <div class="col-sm-7">
            <strong>&nbsp;</strong>
            <ul class="list-unstyled indent-list">
              <li>
                <span class="badge bg-danger">1</span>&nbsp;
                <span>{{ width }}{{ step.unit }} Öffnungsbreite, Bestellmaß</span>
              </li>
              <li>
                <span class="badge bg-danger">2</span>&nbsp;
                <span>{{ height }}{{ step.unit }} Öffnungshöhe, Bestellmaß,</span>
              </li>
              <li>
                <span class="badge bg-dark">3</span>&nbsp;
                <span>min. {{ step.indent_top }}{{ step.unit }} mind. erforderlicher Sturz</span>
              </li>
              <li>
                <span class="badge bg-dark">4</span>&nbsp;
                <span>min. {{ indentCeiling }}{{ step.unit }} mind. erforderlicher Seitenraum</span>
              </li>
              <li>
                <span class="badge bg-dark">5</span>&nbsp;
                <span>min. {{ step.indent_left }}{{ step.unit }} mind. erforderlicher Seitenraum</span>
              </li>
              <li>
                <span class="badge bg-dark">6</span>&nbsp;
                <span>min. {{ step.indent_right }}{{ step.unit }} mind. Garagentiefe</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { computed, ref } from '@vue/reactivity'
import { useStore } from 'vuex'
import { onMounted } from '@vue/runtime-core'
export default {
  setup () {
    const store = useStore()
    const width = ref(0)
    const height = ref(0)
    const currentType = 'width'
    // computed
    const step = computed(() => {
      return store.getters['configurator/currentStep']
    })
    const dimGarage = computed(() => {
      return store.getters['configurator/dimGarage']
    })
    const indentCeiling = computed(() => {
      const ceiling = Number.parseFloat(step.value.indent_ceiling)
      return ceiling + Number.parseInt(height.value)
    })
    const selectedOption = computed(() => {
      return step.value.options.find(o => o.selected)
    })
    const relevantOptions = computed(() => {
      return step.value.options.filter(o => {
        return (o.height === height.value || o.width === width.value) && o.height > 0 && o.width > 0
      })
    })
    // methods
    function onChange (...args) {
      store.dispatch('configurator/selectDimensionGarageTable', { height: height.value, width: Number.parseFloat(width.value) })
    }
    function onSelectItem (w, h) {
      width.value = w
      height.value = h
      store.dispatch('configurator/selectDimensionGarageTable', { height: height.value, width: Number.parseFloat(width.value) })
    }
    onMounted(() => {
      width.value = selectedOption.value.width
      height.value = selectedOption.value.height
    })
    return {
      width,
      height,
      step,
      currentType,
      dimGarage,
      indentCeiling,
      relevantOptions,
      onChange,
      onSelectItem
    }
  }
}
</script>
<style scoped>
.indent-list li {
  margin-top: 10px;
}
.list-group-item:hover {
  cursor: pointer;
  background-color:#e4e4e4;
}
</style>
